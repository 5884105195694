import React from 'react'
import { connect } from 'react-redux'
import { logoutUser } from '../js-shared/lib/actions/userActions'
import Link from '@components/Link'
import Image from 'next/image'
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DropdownMenu from './DropdownMenu'
import Grid from '@material-ui/core/Grid';
import LanguageSelect from '@components/LanguageSelect'
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import useWindowSize from "hooks/useWindowSize";

const useStyles = theme => ({
  root: {
    width: 'fit-content',
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    padding: 0
  },
  bar: {
    boxShadow: '0 0px 0px 0px',
    paddingLeft: 0,
    marginLeft: 0
    // borderBottom: '0.5px solid gainsboro',
  },
  // Avatar
  square: {
    padding: theme.spacing(4), 
  },
  small: {
    marginRight: theme.spacing(0.5),
    marginLeft: 0,
    width: theme.spacing(20),
    height: theme.spacing(5.5),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0),
    color: theme.palette.primary,
  },
  title: {
    flexGrow: 1,
  },
  
});

function Navbar(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const size = typeof window !== "undefined" ? useWindowSize() : { width: 0 };
  return (
    <div>
      {!props.stationMap ? (
        <Container maxWidth="lg">
          <AppBar
            color="transparent"
            className={classes.bar}
            position="relative"
            component="div"
          >
            <Toolbar style={{ paddingLeft: 0 }}>
              <Link href="/" naked>
                <Avatar
                  className={classes.small}
                  variant="square"
                  alt="evloader"
                  src="/images/logo1.png"
                />
              </Link>
              <Typography variant="h6" className={classes.title} />

              {props.user.isAuthenticated ? (
                <div>
                  <Grid container alignItems="center" className={classes.root}>
                    <Box mr={-1}>
                      <LanguageSelect />
                    </Box>
                    <Box ml={-1}>
                      <DropdownMenu />
                    </Box>
                  </Grid>
                </div>
              ) : (
                <>
                  <LanguageSelect />
                  <Button
                    className={classes.btn}
                    disableElevation
                    color="primary"
                    variant="contained"
                    component={Link}
                    href="/login"
                    naked
                  >
                    {t("Σύνδεση")}
                  </Button>
                </>
              )}
            </Toolbar>
          </AppBar>
        </Container>
      ) : (
        <AppBar
          color="#fff"
          className={classes.bar}
          position="relative"
          component="div"
        >
          <Toolbar>
            <Link href="/" naked>
              <Avatar
                className={classes.small}
                variant="square"
                alt="evloader"
                src="/images/logo1.png"
              />
            </Link>
            <Typography variant="h6" className={classes.title} />

            {props.user.isAuthenticated ? (
              <div>
                <Grid container alignItems="center" className={classes.root}>
                  <Box mr={-1}>
                    <LanguageSelect />
                  </Box>
                  <Box ml={-1}>
                    <DropdownMenu />
                  </Box>
                </Grid>
              </div>
            ) : (
              <>
                <LanguageSelect />
                <Button
                  className={classes.btn}
                  disableElevation
                  color="primary"
                  variant="contained"
                  component={Link}
                  href="/login"
                  naked
                >
                  {t("Σύνδεση")}
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}
const mapDispatchToProps = dispatch => {
  return {
    logoutUser: () => dispatch(logoutUser())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Navbar));




 