import React, { useEffect } from "react";
import { connect } from 'react-redux'
import Navbar from "./Navbar"
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next';


const PublicPage = (props) => {
  const router = useRouter()
  const { locale } = router
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [])

  if (typeof window !== 'undefined' && window.location.host === "evloader.com" && window.gtag) {
    window.gtag('config', 'G-D8K0XMP7J5', {'page_path': window.location.pathname});
    if (props.user.isAuthenticated && props.user.user !== null) {
      window.gtag('config', 'G-D8K0XMP7J5', {'user_id': props.user.user.id});
    }
  }
  
  return (
    <>
      {(props.showNavbar === true || props.showNavbar === undefined || props.showNavbar === null) && (
        <Navbar color='transparent'/>
      )}
      {props.children}
    </>
  )
}

const mapStateToProps = state => {
	return {
		user: state.user
	}
}

export default connect(
	mapStateToProps,
	{}
)(PublicPage);