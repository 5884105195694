import React from 'react';
import { connect } from 'react-redux'
import { logoutUser } from '../js-shared/lib/actions/userActions'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import NavLink from '@components/Link'
import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import useWindowSize from "hooks/useWindowSize";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    width: 28,
    height: 28,
    color: "#7f7f7f",
  },
  arrow: {
    color: "#7f7f7f",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.7)",
    textDecoration: "none",
  },
  menu: {
    marginTop: "40px",
  },
  IconButton: {
    backgroundColor: "transparent",
    position: "relative",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function DropdownMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const size = typeof window !== "undefined" ? useWindowSize() : { width: 0 };

  const { t } = useTranslation();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        className={classes.IconButton}
      >
        <PersonIcon className={classes.icon} />
        {props.user.user && (
          <Typography
            variant="subtitle2"
            gutterBottom
            style={{ marginTop: "8px", color: "#7f7f7f" }}
          >
            {size.width > 600 &&
              <>
              {props.user.user.first_name}
              </>
            }
          </Typography>
        )}
        <ArrowDropDownIcon className={classes.arrow} />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={open}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem onClick={handleClose}>
          <NavLink underline="none" className={classes.link} href="/stations-map">
            {t("Χάρτης Σταθμών")}
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink underline="none" className={classes.link} href="/profile">
            {t("Προφίλ")}
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink underline="none" className={classes.link} href="/charges">
            {t("Φορτίσεις")}
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink underline="none" className={classes.link} href="/wallet">
            {t("Πορτοφόλι")}{" "}
          </NavLink>
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <NavLink underline='none' className={classes.link} href="/favorite-stations">Αγαπημένοι Σταθμοί ​</NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink underline='none' className={classes.link} href="/my-reviews">Αξιολογήσεις ​</NavLink>
        </MenuItem>*/}
        <MenuItem onClick={handleClose}>
          <NavLink underline='none' className={classes.link} href="/my-vehicles">{t("Τα αυτοκίνητά μου")}​</NavLink>
        </MenuItem> 
        <Divider />

        {props.user.user && props.user.user.role.id === 2 && (
          <MenuItem onClick={handleClose}>
            <NavLink
              underline="none"
              className={classes.link}
              href="/station-owner/dashboard"
            >
              {t("Διαχείριση Σταθμών")}​
            </NavLink>
          </MenuItem>
        )}

        {/* <MenuItem onClick={handleClose}>
            <NavLink underline='none' className={classes.link} href="/station-owner/dashboard">Διαχείρηση Σταθμών​</NavLink>
        </MenuItem> */}

        <Divider />

        <MenuItem onClick={handleClose}>
          <NavLink
            underline="none"
            className={classes.link}
            onClick={props.logoutUser}
            href="/login"
          >
            {t("Αποσύνδεση")}
          </NavLink>
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}
const mapDispatchToProps = dispatch => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownMenu);