import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { updateUserDetails } from "@js-shared/lib/actions/userActions"
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router'
import { updatePreferredLang } from '@js-shared/lib/actions/configActions'
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

function LanguageSelect(props) {
  const router = useRouter()
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (typeof props.preferredDeviceLang !== "undefined" && props.preferredDeviceLang !== null) {
      i18n.changeLanguage(props.preferredDeviceLang)
      moment.locale(props.preferredDeviceLang)
    } else {
      // If redux's preferredDeviceLang is null or undefined update it with the value already
      // detected from i18n next (in App.js)
      // Maybe this is not strictly needed
      props.updatePreferredLang(i18n.language.split("-")[0])
    }
  }, [])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleChangeLanguage = (event, lng='en') => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    i18n.changeLanguage(lng);
    // Save preferred language for later when user opens again the platform from their browser
    // (i.e. rembemr their language choice)
    // Maybe this is not strictly needed
    props.updatePreferredLang(lng.split("-")[0]);
    if (props.user.isAuthenticated && props.user.user) {
      let updatedUser = props.user.user
      const language = lng.split("-")[0]
      updatedUser.settings.lang = language
      props.updateUserDetails(updatedUser)
    }
    router.push(router.asPath, router.asPath, { locale: lng.split("-")[0], shallow: true })
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      {typeof window !== 'undefined' && (
        <>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={{ color: "#7f7f7f" }}
          >
            {i18n.language.split('-')[0]}
            <ArrowDropDownIcon className={classes.arrow} />
          </Button>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={(event) => handleChangeLanguage(event, i18n.language)}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem onClick={(event) => handleChangeLanguage(event, 'el-GR')}>Ελληνικά (GR)</MenuItem>
                      <MenuItem onClick={(event) => handleChangeLanguage(event, 'en-US')}>English (EN)</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return { 
    user: state.user,
    preferredDeviceLang: state.config.preferredDeviceLang
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUserDetails: (newUserDetails) =>
      dispatch(updateUserDetails(newUserDetails)),
    updatePreferredLang: (lang) => dispatch(updatePreferredLang(lang)),
  };
};

export default connect(
  mapStateToProps, // State
  mapDispatchToProps // ActionCreators
)(LanguageSelect);